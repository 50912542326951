import '../styles/list.scss'
import '../styles/generalTabel.scss'
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import img from '../assets/Youtube video player.png'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar'
import ButtomNav from "../component/ButtomNav";
import Helper from '../component/Helper';

const CreateSalesTax = () => {
    return (
        <div>
            <UserNavbar />
            <div id="ListUsers">
                <div className="container">
                    <div className="main ">
                        <div className="header">
                            <div className="left">
                                <h1>Create Group Tax</h1>
                            </div>
                            <div className="right">
                               
                            </div>
                        </div>
                        <div id="ListUsers" className="">
                            <Helper />
                        </div>
                        <div className="container" id='FormInput'>
                            <div className="formHolderContact">

                                <div className="row mt-3" >
                                    <div className="col-md-6 right">
                                        <div className="PurchaseDetails">
                                           
                                            <div className="">
                                                <label htmlFor="">Group Number/Name</label>
                                                <div className="email-input">
                                                    <input type="text" placeholder='Enter item number' />
                                                </div>
                                            </div>
                                            <span>Discount is applied before sales tax</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 left">
                                       




                                        <div className="Description mt-3 mb-5">
                                            <h1> Description on Purchase</h1>
                                            <textarea name="" id="" cols="30" rows="10"></textarea>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="tableSection ">
                            
                            <div className="p-2">
                                <div id='GeneralTable'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Tax Item</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Tax Agent</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               
                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                                
                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                            </tr>

                                            <tr>

                                                <td>100230</td>
                                                <td>Carton Biscuit</td>

                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                            </tr>

                                            <tr>

                                                <td>100230</td>
                                                <td>Carton Biscuit</td>

                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginate">
                                    <nav aria-label="...">
                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                        <span>Displaying 1 - 2 of 2</span>
                                    </nav>
                                    <button className="btn">
                                        <GetAppIcon />
                                        Export as CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav />
        </div>
    )
}


export default CreateSalesTax