import ButtomNav from '../component/ButtomNav'
import UserNavbar from '../usersdashboard/UserNavbar'
import '../styles/CreateNewVendor.scss'
import GeneralNavBar from "../component/GeneralNavBar";
const AdjustVendorOpeningBalances = () => {


  return (
    <div>
      <UserNavbar />
      <div className="container"><NavItem /></div>
      <div id="Invoice" className='ProcesReceipt'>
        <div className="container">
          <div className="Invoice-container ">
            <div className="invoice-header">
              <div className="left">
                <div className="top">
                  <h1>Adjust Vendor Opening Balances</h1>
                </div>

              </div>
              <div className="right">
                <div className="top d-flex gap-2">
                  <button>List of Vendor Opening Balance</button>
                  <button>Print</button>
                </div>

              </div>
            </div>
            <div className="main">
              <div className="header">
                <div className="note">
                  <span>i</span>
                  <p>Please select a vendor for which you wish to adjust the opening balance. Enter a reason for the adjustment and then enter the new opening balance. Should you wish to adjust the opening balance date, enter a new date.</p>
                </div>
              </div>
              <form action="" id='FormInput'>
                <div className="container">
                  <div className="row mt-4" >
                    <div className="col-md-6">
                     

                      <div className="select mt-2">
                        <label>Vendor Name <span>*</span></label>
                        <select class="role" aria-label="Default select example">
                          <option selected>Select vendor</option>
                          <option value="1">Nigeria</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="btnHolder">
                        <button>Add new category</button>
                      </div>

                      <div className="mt-2">
                        <label htmlFor="">Current Opening Balance<span>*</span></label>
                        <div className="email-input">
                          <input type="text" placeholder='₦0.00' />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="">New Opening Balance <span>*</span></label>
                        <div className="email-input">
                          <input type="text" placeholder='₦0.00' />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="">Opening Balance as At<span>*</span></label>
                        <div className="email-input">
                          <input type="date" placeholder='Vendor Name' />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 left">
                      <div className="Message ">
                        <div className="title">
                          <div className="titleHolder mb-2 d-flex justify-content-between">
                            <span>Reason</span>
                          </div>
                          <textarea className='w-100  btn-outline-info' name="" id="" placeholder='This will show up on the invoice'></textarea>
                        </div>
                      </div>

                    </div>
                  </div>
              

                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
      <ButtomNav />
    </div>
  )
}
const NavItem = () => {
  return (
      <div className="d-flex navHolder">
      <GeneralNavBar name='Vendor Dashboard' to='/VendorDashboard' />
      <GeneralNavBar name='Add New Vendor' to='/CreateNewVendor' />
      <GeneralNavBar name='Vendor Adjustments' to='/VendorAdjustments' />
      <GeneralNavBar name='List of Vendors' to='/ListVendors' />
      <GeneralNavBar name='Vendor Categories' to='/VendorCategories' />
      <GeneralNavBar name='Vendor Purchase Orders' to='/VendorPurchaseOrders' />
      <GeneralNavBar name='Vendor Invoices' to='/VendorInvoices' />
      <GeneralNavBar name='Vendor Returns' to='/VendorReturns' />
      <GeneralNavBar name='Vendor Payment' to='/VendorPayment' />
      <GeneralNavBar name='Process Payment' to='/ProcessPayment' />
      <GeneralNavBar name='Adjust Opening Balances' to='/AdjustVendorOpeningBalances' />
      <GeneralNavBar name='New Vendor Category' to='/NewVendorCategory' />
      <GeneralNavBar name='Process Vendor Return' to='/ProcessVendorReturn' />
      <GeneralNavBar name='Process Vendor Adjustment' to='/ProcessVendorAdjustment' />
      </div>
  )
}
export default AdjustVendorOpeningBalances