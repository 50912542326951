import '../styles/CreateNewVendor.scss'
import UserNavbar from '../usersdashboard/UserNavbar'
import "../styles/addcustomer.scss";
import '../styles/inputs.scss'
import { Link } from 'react-router-dom';
import GeneralNavBar from "../component/GeneralNavBar";
import CreateVendor from '../VENDORS/CreateVendor';
import ButtomNav from '../component/ButtomNav';
const Notes = () => {
  return (
      <div>
          <div>
              <UserNavbar />
              <div className="container"><NavItem /></div>
              <div id="CreateNewVendor">
                  <div className="">
                      <div id="AddNewCustomer">
                          <div className="container" >
                              <div className="formHolderContact">
                                  <CreateVendor/>

                                  <div className="row mt-5" id='FormInput' >
                                      <div className="col-md-6 right">
                                          <div className="">
                                              <label htmlFor="">Vendor Name <span>*</span></label>
                                              <div className="email-input">
                                                  <input type="text" placeholder='Vendor Name' />
                                              </div>
                                          </div>

                                          <div className="select">
                                              <label>Category</label>
                                              <select class="role" aria-label="Default select example">
                                                  <option selected>Select category</option>
                                                  <option value="1">Nigeria</option>
                                                  <option value="2">Two</option>
                                                  <option value="3">Three</option>
                                              </select>
                                          </div>
                                          <div className="btnHolder">
                                              <button>Add new category</button>
                                          </div>
                                      </div>
                                      <div className="col-md-6 left">
                                          <div className="">
                                              <label htmlFor="">VAT reference</label>
                                              <div className="email-input">
                                                  <input type="text" placeholder='Enter VAT reference' />
                                              </div>
                                          </div>

                                          <div className="mt-2">
                                              <label htmlFor="">Credit limit</label>
                                              <div className="email-input">
                                                  <input type="text" placeholder='Enter VAT reference' />
                                              </div>
                                          </div>


                                      </div>
                                  </div>
                                  <div className="address" id='FormInput'>
                                      <div className="tab">
                                          <ul>
                                              <li ><Link to='/CreateNewVendor'>Contact Details</Link></li>
                                              <li ><Link to='/BankDetails'>Banking Details</Link></li>
                                              <li><Link to='/Address'>Address</Link></li>
                                              <li><Link to='/AdditionalContact'>Additional Contact</Link></li>
                                              <li ><Link to='/Payment'>Payment and Billing</Link></li>
                                              <li className="tabActive"><Link to='/Note'>Notes</Link></li>
                                              <li><Link to='/Upload'>Attachments</Link></li>
                                          </ul>
                                      </div>
                                      <div className="formHoldering">

                                          <div className="row right">
                                              <div className="note">
                                                  <label htmlFor="">Notes</label>
                                                  <div className="noteHolder">
                                                      <textarea
                                                          name=""
                                                          id=""
                                                          placeholder="Write note..."
                                                      ></textarea>
                                                  </div>
                                              </div>
                                          </div>
                                         
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <ButtomNav/>
      </div>
  )
}
const NavItem = () => {
    return (
        <div className="d-flex navHolder">
            <GeneralNavBar name='Vendor Dashboard' to='/VendorDashboard' />
            <GeneralNavBar name='Add New Vendor' to='/CreateNewVendor' />
            <GeneralNavBar name='Vendor Adjustments' to='/VendorAdjustments' />
            <GeneralNavBar name='List of Vendors' to='/ListVendors' />
            <GeneralNavBar name='Vendor Categories' to='/VendorCategories' />
            <GeneralNavBar name='Vendor Purchase Orders' to='/VendorPurchaseOrders' />
            <GeneralNavBar name='Vendor Invoices' to='/VendorInvoices' />
            <GeneralNavBar name='Vendor Returns' to='/VendorReturns' />
            <GeneralNavBar name='Vendor Payment' to='/VendorPayment' />
            <GeneralNavBar name='Process Payment' to='/ProcessPayment' />
            <GeneralNavBar name='Adjust Opening Balances' to='/AdjustVendorOpeningBalances' />
            <GeneralNavBar name='New Vendor Category' to='/NewVendorCategory' />
            <GeneralNavBar name='Process Vendor Return' to='/ProcessVendorReturn' />
            <GeneralNavBar name='Process Vendor Adjustment' to='/ProcessVendorAdjustment' />
        </div>
    )
}
export default Notes