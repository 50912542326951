import '../styles/customerDashboard.scss'
import '../styles/tabelz.scss'
import GeneralNavBar from "../component/GeneralNavBar";
import UserNavbar from "../usersdashboard/UserNavbar";
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Helper from '../component/Helper';
const VendorDashboard = () => {
  return (

    <div>
      <UserNavbar/>
      <div id="CustomersDashBoard">
        <div className='container'>

          <div className="fsLayer">
            <h1 className='mt-3'>Vendor Dashboard</h1>
            <NavItem />
          </div>

          <div className="secondLayer mt-5">
            <div id="ListUsers" className="">
              <Helper />
            </div>
            <div className="row">
              <div className="col-md-7 left">
                <h1 className='Top'>Top Vendors</h1>
                <div className="heading">
                  <div className="date">


                    <div className="inputDate d-flex">
                      <div className="d-flex gap-2">
                        From:
                        <input type="date" />
                      </div>
                      <div className="d-flex gap-2">
                        To:
                        <input type="date" />
                      </div>

                    </div>
                  </div>
                  <div className="setting">
                    <span>Sort by:</span>
                    <div class="dropdown">
                      <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Balance
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item active" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>

                      </ul>
                    </div>
                    <SettingsIcon />
                    <SwapVertIcon />
                  </div>
                </div>
                <div className="table mt-2">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" className='col-span-2'>Purchases</th>
                        <th scope="col">Outstanding Bal.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-5 right">
                <div className="innerHolder">
                  <div className="heading">
                    <h1>All Vendor Report </h1>
                  </div>
                  <div className="sec-one d-flex  mt-4">
                    <p className='one'>List of Vendors</p>
                    <p>List of Vendors</p>
                  </div>
                  <div className="secTwo">
                    <div className="title">
                      <h1>Vendor Transaction Reports</h1>
                    </div>
                    <div className="para">
                      <p>Sales by Customer</p>
                      <p>Sales by Customer</p>
                    </div>
                    <div className="para">
                      <p className='one'>Customer Transactions</p>
                      <p >A list of all transactions for customers e.g. Invoices, receipts.</p>
                    </div>
                  </div>

                  <div className="secTwo">
                    <div className="title">
                      <h1>Customer Balances and Statements</h1>
                    </div>
                    <div className="para">
                      <p>Days Outstanding</p>
                      <p>How many days a customer’s balance has been outstanding.</p>
                    </div>
                    <div className="para">
                      <p className='one'>Statement Run</p>
                      <p >Generate and distribute statemnets to all customers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabling mt-2 lefts">
            <div className="heading mb-2">
              <h1>Vendor Balances - Days Outstanding</h1>
              <div className="setting">
                <span>Sort by:</span>
                <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Balance
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item active" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>

                  </ul>
                </div>
                <SettingsIcon />
                <SwapVertIcon />
              </div>
            </div>
            <div className="tablez mt-2 ">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Vendor</th>
                    <th scope="col" className=''>Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Document Date</th>
                    <th scope="col">Reference</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                  <tr>
                    <td scope="row">2</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                  <tr>
                    <td scope="row">3</td>
                    <td>Vendor A</td>
                    <td>michael.mitc@example.com</td>
                    <td>+2349022618242</td>
                    <td>02/10/2022</td>
                    <td>RCP0000001</td>
                    <td>24/10/2022</td>
                    <td>₦12,000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="footer container">
          <p>© Copyright Accounting Saas.</p>
          <ul>
            <li>Accounting Saas</li>
            <li>Terms & conditions</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </div>

    </div>
  )
}
const NavItem = () => {
  return (
      <div className="d-flex navHolder">
        <GeneralNavBar name='Vendor Dashboard' to='/VendorDashboard' />
        <GeneralNavBar name='Add New Vendor' to='/CreateNewVendor' />
        <GeneralNavBar name='Vendor Adjustments' to='/VendorAdjustments' />
        <GeneralNavBar name='List of Vendors' to='/ListVendors' />
        <GeneralNavBar name='Vendor Categories' to='/VendorCategories' />
        <GeneralNavBar name='Vendor Purchase Orders' to='/VendorPurchaseOrders' />
        <GeneralNavBar name='Vendor Invoices' to='/VendorInvoices' />
        <GeneralNavBar name='Vendor Returns' to='/VendorReturns' />
        <GeneralNavBar name='Vendor Payment' to='/VendorPayment' />
        <GeneralNavBar name='Process Payment' to='/ProcessPayment' />
        <GeneralNavBar name='Adjust Opening Balances' to='/AdjustVendorOpeningBalances' />
        <GeneralNavBar name='New Vendor Category' to='/NewVendorCategory' />
        <GeneralNavBar name='Process Vendor Return' to='/ProcessVendorReturn' />
        <GeneralNavBar name='Process Vendor Adjustment' to='/ProcessVendorAdjustment' />
      </div>
  )
}
export default VendorDashboard