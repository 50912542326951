import '../styles/list.scss'
import '../styles/generalTabel.scss'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar';
import GeneralNavBar from "../component/GeneralNavBar";
import { NavLink } from "react-router-dom";
import ButtomNav from "../component/ButtomNav";
const PayrollDecductions = () => {
    return (
        <div>
            <UserNavbar />

            <div id="ListUsers">
                <div className="container">

                    <div className="main">
                        <NavItem/>
                        <div className="header">
                            <div className="left">
                                <h1>Create Payroll Deduction</h1>
                            </div>
                            <div className="right">
                                <button className="button btn">
                                    <NavLink to='' style={{
                                        textDecoration: 'none',
                                        color: "white"
                                    }}>
                                        List of Deductions
                                    </NavLink>
                                </button>

                            </div>
                        </div>
                        <div className="payNav mb-2">

                            <div className="formHoldering p-3" id='FormInput'>
                                <div className="paymentMethod">
                                    <div className="row">
                                        <div className="col-md-6 right">
                                            <div className="dividedForm">
                                                <div className="d-flex">
                                                    <h5>Statutory Deduction Not Applicable <input type="checkbox" style={{ width: 25 }} /></h5>

                                                </div>

                                                <div className="d-flex mt-2 gap-3" style={{ alignItems: 'center' }}>
                                                    <p>Auto</p><input type="checkbox" style={{ width: 25 }} />
                                                    <p>PAYE</p>
                                                    <input type="text" />

                                                </div>
                                                <div className="select mt-2">
                                                    <div className="d-flex mt-2 gap-3" style={{ alignItems: 'center' }}>
                                                        <p>Auto</p><input type="checkbox" style={{ width: 25 }} />
                                                        <p>NHF</p>
                                                        <input type="text" />

                                                    </div>
                                                </div>
                                                <div className="d-flex mt-2 gap-3" style={{ alignItems: 'center' }}>
                                                    <p>Auto</p><input type="checkbox" style={{ width: 25 }} />
                                                    <p>PENSION</p>
                                                    <input type="text" />

                                                </div>

                                            </div>
                                        </div>
                                        <div className="left col-md-6">

                                            <div className="dividedForm">
                                                <h3>Non Statutory Deduction</h3>
                                                <h5>Loan   Other Non Statutory deduction</h5>
                                                <p>Saving or other deduction from Salary&Wages</p>
                                                <p>What do you want to call this Non Staatutory deduction?</p>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Describe the deduction name</label>
                                                    <input type="text" placeholder='Amount' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Enter Amount</label>
                                                    <input type="text" placeholder='Amount' />
                                                </div>

                                                <div className="select mt-2 d-flex gap-1" style={{justifyContent:'space-between'}}>
                                                    <div className="">
                                                        <label htmlFor="">Expected Starting date</label>
                                                        <input type="date" />
                                                    </div>

                                                    <div className="">
                                                        <label htmlFor="">Expected Ending date</label>
                                                        <input type="date" />
                                                    </div>
                                                    <div className="select mt-1">
                                                        <label htmlFor="">Processing Frequency</label>
                                                        <select
                                                            class="role"
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="1">Daily</option>
                                                            <option value="2">Weekly</option>
                                                            <option value="3">Bi-weekly</option>
                                                            <option value="1">Semi- monthly</option>
                                                            <option selected>Monthly</option>
                                                            <option value="1">Quartely</option>
                                                            <option value="1">Annual </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Suspension</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select Duration</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6 right">
                                            <div className="dividedForm">
                                                <h3>Create Statutory Seduction</h3>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Deduction Name</label>
                                                    <input type="text" placeholder='Name' />
                                                </div>
                                                <div className="select mt-1">
                                                    <label htmlFor="">Amount to deduct</label>
                                                    <input type="text" placeholder='Item Rate' />
                                                </div>




                                            </div>
                                        </div>
                                        <div className="left col-md-6">
                                            <div className="dividedForm">
                                                <h3>Payroll item Loan or Saving deduction</h3>
                                                <p>Loan  deduction from Salary&Wages</p>
                                                <p>What do you want to call this Non Staatutory deduction?</p>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Describe the deduction name</label>
                                                    <input type="text" placeholder='Amount' />
                                                </div>
                                                <div className="d-flex gap-1">
                                                    <div className="select mt-2">
                                                        <label htmlFor="">Enter Loan Figure</label>
                                                        <input type="text" placeholder='$898000' />
                                                    </div>

                                                    <div className="select mt-2">
                                                        <label htmlFor="">Interst Rate Or Amount</label>
                                                        <input type="text" placeholder='10%' />
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-1">
                                                    <div className="select mt-2">
                                                        <label htmlFor="">Total Loan and amount to repay</label>
                                                        <input type="text" placeholder='Amount' />
                                                    </div>

                                                    <div className="select mt-2">
                                                        <label htmlFor="">Interst Rate Or Amount</label>
                                                        <input type="text" placeholder='10%' />
                                                    </div>
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Duration of payment</label>
                                                    <input type="text" placeholder='Amount' />
                                                </div>
                                                <div className="select mt-2 d-flex gap-1" style={{ justifyContent: 'space-between' }}>
                                                    <div className="">
                                                        <label htmlFor="">Expected Starting date</label>
                                                        <input type="date" />
                                                    </div>

                                                    <div className="">
                                                        <label htmlFor="">Expected Ending date</label>
                                                        <input type="date" />
                                                    </div>
                                                    <div className="select mt-1">
                                                        <label htmlFor="">Processing Frequency</label>
                                                        <select
                                                            class="role"
                                                            aria-label="Default select example"
                                                        >
                                                            <option value="1">Daily</option>
                                                            <option value="2">Weekly</option>
                                                            <option value="3">Bi-weekly</option>
                                                            <option value="1">Semi- monthly</option>
                                                            <option selected>Monthly</option>
                                                            <option value="1">Quartely</option>
                                                            <option value="1">Annual </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Suspension</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select Duration</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        <ButtomNav/>
        </div>
    )
}
const NavItem = () => {
    return (
        <div className="d-flex navHolder">
            <GeneralNavBar name='Create New Employee & List' to='/CreateNewEmployee' />
            <GeneralNavBar name='Create Salary Structue & List' to='/CreateSalarySructure' />
            <GeneralNavBar name='Create Payroll items & List' to='/CreatePayrollitemsList' />
            <GeneralNavBar name='Create Payroll Deduction' to='/PayrollDecductions' />
            <GeneralNavBar name='Leave Approval List' to='/none' />
            <GeneralNavBar name='Process Salary & Processed List' to='/none' />
            <GeneralNavBar name='Time Sheet' to='/EmployeeTimeSheet' />
            <GeneralNavBar name='Payroll Report' to='/ListOfEmployee' />
        </div>
    )
}

export default PayrollDecductions