import UserNavbar from "../usersdashboard/UserNavbar"
import ButtomNav from "../component/ButtomNav";
import GeneralNavBar from "../component/GeneralNavBar"; 
import { NavLink } from "react-router-dom";
const ItemCat = () => {
    return (
        <div>
            <UserNavbar />
            <div className="container"><NavItem /></div>
            <div id="Invoice" className='ProcessReceipt'>
                <div className="container">
                    <div className="Invoice-container ">
                        <div className="invoice-header">
                            <div className="left">
                                <div className="top">
                                    <h1>New Item Category</h1>
                                </div>

                            </div>
                            <div className="right">
                                <div className="top">
                                    <button className="button btn" type="button">
                                        <NavLink to="/ItemDashboardCat" style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}>
                                            Item Categories List
                                        </NavLink>

                                    </button>
                                   
                                </div>

                            </div>
                        </div>
                        <div className="main">
                            <div className="header ">

                            </div>
                            <form action="" id='FormInput'>
                                <div className="container">

                                    <div className="row shipping mt-5">

                                        <div className="col-md-6">
                                            <div className="name mt-2">

                                                <div className="email">
                                                    <label htmlFor="">Category Name</label>
                                                    <div className="email-input">
                                                        <input type="text" placeholder='Enter new category name' />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-md-6 px-5 Process">
                                            <div className="Message ">
                                                <div className="title">
                                                    <div className="titleHolder mb-2 d-flex justify-content-between">
                                                        <span>Description</span>
                                                        <span className='set'>Set Default Meassage</span>
                                                    </div>
                                                    <textarea className='w-100  btn-outline-info' name="" id="" placeholder='This will show up on the invoice'></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <ButtomNav />
        </div>
    )
}
const NavItem = () => {
    return (
        <div className="d-flex navHolder">
            <GeneralNavBar name='Item Dashboard' to='/ItemDashboard' />
            <GeneralNavBar name='Add New Item' to='/CreateNewItem' />
            <GeneralNavBar name='Vendor Adjustments' to='/VendorAdjustments' />
            <GeneralNavBar name='Item Categories' to='/ItemDashboardCat' />
            <GeneralNavBar name='List of Item' to='/ItemDashboarList' />
            <GeneralNavBar name='List of Item Bundles' to='/ListItemBundles' />
            <GeneralNavBar name='Item Bundles' to='/ItemBundle' />
            <GeneralNavBar name='Create Inventory Adjustment' to='/CreateInventoryAdjustment' />
            <GeneralNavBar name='Item Receipt' to='/ItemReceipt' />
            <GeneralNavBar name='List ofAccounts' to='/ListOfAccount' />
            <GeneralNavBar name='Create an Account' to='/CreateAnAccount' />
        </div>
    )
}
export default ItemCat