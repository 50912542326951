import React from 'react'
import Sidebar from './Sidebar'
import '../styles/dashboardStyle.scss'
import '../styles/inputs.scss'
import NavBoard from "../dashboard/NavBoard";
const BudgetsSettings = () => {
  return (
    <div id="HomeDashboard">
      <Sidebar />
      <div id="MainContent" >
        <NavBoard />
        <div className="content">
          <div className="heading">
            <h4>Company</h4>
          </div>
          <div className="row p-3" id="FormInput">
            <div className="col-md-6">
              <form action="" style={{
             
              }}>
                <div>
                  <label htmlFor="">Company Name</label>
                  <div>
                    <input type="text" placeholder="BuskyLTD_Dollar" />
                  </div>
                </div>
                <div className="select mt-2">
                  <label>Currency</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>Euro EUR</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <form action="" style={{
               
              }}>
                <div className="select mt-2">
                  <label>Number Seperators</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>1,000.00</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className='mt-2'>
                  <label htmlFor="">Exchange Rate</label>
                  <div className='d-flex gap-2' style={{
                    alignItems: 'center',
                  }}>
                    <input type="radio" style={{
                      width:30
                    }} />
                    <span style={{
                      width: 200
                    }}>Market Rate</span>
                    <input type="radio" style={{
                      width:30
                    }} />
                    <span style={{
                      width:200
                    }}>Custom Rate</span>
                    <input type="text" placeholder="BuskyLTD_Dollar" />
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
        <hr />
        <div className="content">
          <div className="heading">
            <h4>Forecast</h4>
          </div>
          <div className="row p-3" id="FormInput">
            <div className="col-md-6">
              <form action="" style={{
            
              }}>
                <div className="select mt-2">
                  <label>Fiscal Year Cycle</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>January</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className='mt-2'>
                  <label htmlFor="">Plan</label>
                  <div className='d-flex gap-2' style={{
                    alignItems: 'center',
                  }}>
                    <input type="radio" style={{
                      width: 30
                    }} />
                    <span style={{
                      width: 300
                    }}>Budget plan of 12 months</span>
                    <input type="radio" style={{
                      width: 30
                    }} />
                    <span style={{
                      width: 300
                    }}>Forecast for years</span>
                   
                  </div>
                </div>
                <div className="select mt-4">
                  <label>Monthly Detail</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>2 years of monthly detail</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <form action="" style={{
              
              }}>
                <div className="select mt-2">
                  <label>First Year of Forecast</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>2023</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="select mt-2">
                  <label>Length of Forecast</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>2 years</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="select mt-2">
                  <label>Multi-Month Charges</label>
                  <select class="role" aria-label="Default select example">
                    <option selected>Spread out multi-month charges</option>
                    <option value="1">Nigeria</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr />
        <div className="Collaborators">
          <form id="FormInput">
            <div className="heading">
              <h4>Collaborators</h4>
            </div>
            <div className="d-flex">
              <input type="checkbox" defaultChecked style={{
                width:30
              }}/>
              <spna>Leslie Alexander</spna>
            </div>
            <div className="d-flex">
              <input type="checkbox" defaultChecked style={{
                width: 30
              }} />
              <spna>Jane Cooper</spna>
            </div>
            <div className="d-flex">
              <input type="checkbox" defaultChecked style={{
                width: 30
              }} />
              <spna>Jane Cooper</spna>
            </div>
           
          </form>
        </div>
      </div>

    </div>
  )
}

export default BudgetsSettings