import ButtomNav from '../component/ButtomNav'
import UserNavbar from './UserNavbar'
import '../styles/inputs.scss'
import '../styles/in.scss'
import { useState } from 'react'
import Intable from "../component/Intable";
import imgIcon from "../assets/fluent_folder-48-filled.png";
import GeneralNavBar from "../component/GeneralNavBar";
import { useEffect } from 'react'
import Helper from "../component/Helper";
const CreateReceivable = () => {
    let [tableData, setTableData] = useState([
        {
            "id": "1",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "Ibadan Class",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        },
        {
            "id": "2",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "middle-classes",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        },
        {
            "id": "3",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "middle-classes",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        },
        {
            "id": "4",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "middle-classes",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        },
        {
            "id": "5",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "middle-classes",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        },
        {
            "id": "6",
            "Type": "new",
            "Selection": "middle-class",
            "desc": "lorem in making",
            "QTY": 1,
            "Class": "middle-classes",
            "Amount": 6760,
            "Disc": 20,
            "VAT": 42,
            "VATs": 0.5,
            "Total": 9707
        }
    ]);
    const add = (newData) => {
        console.log(newData)
        setTableData([...tableData, newData])
    }
    const remove = (id) => {
        if (id === null) return;
        setTableData(tableData.filter((value, index, array) => id !== value.id));
    }
    const removeAll = () => {
        setTableData([]);
    }

    return (
        <div>
            <UserNavbar />
            <div className="container"><NavItem /></div>
            <div id="Invoice">
                <div className="container">
                    <div className="Invoice-container ">
                        <div className="invoice-header">
                            <div className="left">
                                <div className="top">
                                    <h1>Create Customer Write-Offs</h1>
                                </div>

                            </div>
                            <div className="right">
                                <div className="top">
                                    <button>Print</button>
                                </div>

                            </div>
                        </div>
                        <div className="main">
                            <div id="ListUsers" className="">
                                <Helper />
                            </div>
                            <div className="header ">
                                <div className="left">
                                    <h1>Customer - withholdings/written off:</h1>
                                    {/* <div className="inputHolder">
                                        <div className="in-check">
                                            <input type="checkbox" defaultChecked />
                                            <span>Shipping</span>
                                        </div>
                                        <div className="in-check">
                                            <input type="checkbox" defaultChecked />
                                            <span>Total Discount</span>
                                        </div>
                                        <div className="in-check">
                                            <input type="checkbox" defaultChecked />
                                            <span>Class</span>
                                        </div>
                                        <div className="in-check">
                                            <input type="checkbox" defaultChecked />
                                            <span>Deposit</span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="right">
                                    <h1>Amount Outstanding:</h1>
                                    <p>₦0.00</p>
                                </div>
                            </div>
                            <form action="" id='FormInput'>
                                <div className="container">
                                    <div className="row mt-5 customer">
                                        <h1>Customer Details</h1>
                                        <div className="col-md-6">
                                            <div className="name mt-2">

                                                <div className="select">
                                                    <label htmlFor="">Customer Name*</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select customer</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="btnHolder">
                                                    <button>Create Write-Offs</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="email">
                                                <label htmlFor="">Email</label>
                                                <div className="email-input">
                                                    <input type="text" placeholder='Separate multiple emails with commas' />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row shipping mt-4">
                                       
                                        <div className="col-md-6">
                                            <div className="name mt-2">

                                                <div className="select">
                                                    <label htmlFor="">Ship via*</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select customer</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="email">
                                                    <label htmlFor="">Date</label>
                                                    <div className="email-input">
                                                        <input type="date" />
                                                    </div>
                                                </div>

                                                <div className="email">
                                                    <label htmlFor="">Reference no.</label>
                                                    <div className="email-input">
                                                        <input type="text" placeholder='#56567' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div>
                                                <label htmlFor="">Amount Debit</label>
                                                <div className="div d-flex gap-3 mt-2"> 
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select customer</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>


                                                </div>
                                            </div>
                                            {/* <div className="div d-flex gap-3 mt-2">
                                                <input type="text" placeholder='City/Town' />
                                                <input type="text" placeholder='State/Province' />

                                            </div>
                                            <div className="div d-flex gap-3 mt-2">
                                                <input type="text" placeholder='Postal Code' />
                                                <select
                                                    class="role"
                                                    aria-label="Default select example"
                                                >
                                                    <option selected>Select customer</option>
                                                    <option value="1">Nigeria</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>


                                            </div> */}

                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div id='GeneralTable'>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input type="checkbox" />
                                            </th>
                                            <th scope="col">DESCRIPITION</th>
                                            <th scope="col">DUE DATE</th>
                                            <th scope="col">ORIGINAL AMOUNT</th>
                                            <th scope="col">OPEN BALANCE</th>
                                            <th scope="col">AMOUNT WRITTEN-OFF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="checkbox" /></td>
                                           
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <div className="totalW">
                                    <div className="d-flex gap-5 mt-3" style={{
                                        alignItems: "center",
                                        justifyContent: "flex-end"
                                    }}>
                                        <h5>Amount withholding/written-off</h5>
                                        <h6>#0.00</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ButtomNav />
        </div>
    )
}
const NavItem = () => {
    return (
        <div className="d-flex navHolder">
            <GeneralNavBar name='Customer Dashboard' to='/csdasboard' />
            <GeneralNavBar name='Add New Customer' to='/add-customer' />
            <GeneralNavBar name='List of Customers' to='/list' />
            <GeneralNavBar name='Customer Sales Order' to='/sales' />
            <GeneralNavBar name='Customer Categories' to='/CustomerCategories' />
            <GeneralNavBar name='Customer Credit Notes' to='/CustomerCredit' />
            <GeneralNavBar name='Customer Receipt' to='/CustomerReceipt' />
            <GeneralNavBar name='List of Sales Reps' to='/ListSales' />
            <GeneralNavBar name='Create Invoice' to='/invoice' />
            <GeneralNavBar name='Process Receipt' to='/ProcessReceipt' />
            <GeneralNavBar name='Customer Write-Offs' to='/CustomerWrite' />
            <GeneralNavBar name='Recurring Invoice' to='/RecurringInvoice' />
        </div>
    )
}
export default CreateReceivable