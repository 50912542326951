import '../styles/list.scss'
import '../styles/generalTabel.scss'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar';
import Helper from '../component/Helper';
import GeneralNavBar from "../component/GeneralNavBar";
import { NavLink } from "react-router-dom";

const VendorAdjustments = () => {
  return (
    <div>
      <UserNavbar />
      <div className="container"><NavItem /></div>
      <div id="ListUsers">
        <div className="container">
          <div className="main ">
            <div className="header">
              <div className="left">
                <h1>Vendor Adjustments</h1>
              </div>
              <div className="right">
                
                <button className="button btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <NavLink to='/AdjustVendorOpeningBalances' style={{color:"white", textDecoration:"none"}}> Create Vendor Adjustment</NavLink>
                </button>
                
              </div>
            </div>
            <Helper/>
            <div className="tableSection ">
              <div className="nav mb-2">
                <img src={dropArrow} alt="" />
                <span>Select</span>
                <li>Delete</li>
                
              </div>
              <div className="p-2">
                <div id='GeneralTable'>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col"><input type="checkbox" /></th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Document No.</th>
                        <th scope="col">Vendor Ref.</th>
                        <th scope="col">Date</th>
                        <th scope="col">Total</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <td>Company A</td>
                        <td>RCP0000001</td>
                        <td></td>
                        <td>9/23/16</td>
                        <td>₦12,000</td>
                       

                        <td>
                          <span class="dropdown">
                            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Actions
                            </span>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="#">View</a></li>
                              <li><a class="dropdown-item" href="#">Create</a></li>
                              <li><a class="dropdown-item" href="#">Edit</a></li>
                              <li><a class="dropdown-item" href="#">Delete</a></li>
                            </ul>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <td>Company A</td>
                        <td>RCP0000001</td>
                        <td></td>
                        <td>9/23/16</td>
                        <td>₦12,000</td>
                       

                        <td>
                          <span class="dropdown">
                            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Actions
                            </span>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="#">View</a></li>
                              <li><a class="dropdown-item" href="#">Create</a></li>
                              <li><a class="dropdown-item" href="#">Edit</a></li>
                              <li><a class="dropdown-item" href="#">Delete</a></li>
                            </ul>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <td>Company A</td>
                        <td>RCP0000001</td>
                        <td></td>
                        <td>9/23/16</td>
                        <td>₦12,000</td>
                       

                        <td>
                          <span class="dropdown">
                            <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Actions
                            </span>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="#">View</a></li>
                              <li><a class="dropdown-item" href="#">Create</a></li>
                              <li><a class="dropdown-item" href="#">Edit</a></li>
                              <li><a class="dropdown-item" href="#">Delete</a></li>
                            </ul>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="paginate">
                  <nav aria-label="...">
                    <ul class="pagination">
                      <li class="page-item disabled">
                        <a class="page-link">Previous</a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">2</a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">Next</a>
                      </li>
                    </ul>
                    <span>Displaying 1 - 2 of 2</span>
                  </nav>
                  <button className="btn">
                    <GetAppIcon />
                    Export as CSV
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
const NavItem = () => {
  return (
      <div className="d-flex navHolder">
        <GeneralNavBar name='Vendor Dashboard' to='/VendorDashboard' />
        <GeneralNavBar name='Add New Vendor' to='/CreateNewVendor' />
        <GeneralNavBar name='Vendor Adjustments' to='/VendorAdjustments' />
        <GeneralNavBar name='List of Vendors' to='/ListVendors' />
        <GeneralNavBar name='Vendor Categories' to='/VendorCategories' />
        <GeneralNavBar name='Vendor Purchase Orders' to='/VendorPurchaseOrders' />
        <GeneralNavBar name='Vendor Invoices' to='/VendorInvoices' />
        <GeneralNavBar name='Vendor Returns' to='/VendorReturns' />
        <GeneralNavBar name='Vendor Payment' to='/VendorPayment' />
        <GeneralNavBar name='Process Payment' to='/ProcessPayment' />
        <GeneralNavBar name='Adjust Opening Balances' to='/AdjustVendorOpeningBalances' />
        <GeneralNavBar name='New Vendor Category' to='/NewVendorCategory' />
        <GeneralNavBar name='Process Vendor Return' to='/ProcessVendorReturn' />
        <GeneralNavBar name='Process Vendor Adjustment' to='/ProcessVendorAdjustment' />
      </div>
  )
}
export default VendorAdjustments