import '../styles/list.scss'
import '../styles/generalTabel.scss'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar';
import GeneralNavBar from "../component/GeneralNavBar";
import { NavLink } from "react-router-dom";
import ButtomNav from '../component/ButtomNav';

const CreatePayrollitemsList = () => {
    return (
        <div>
            <UserNavbar />

            <div id="ListUsers">
                <div className="container">
                    <NavItem />
                    <div className="main ">
                        <div className="header">

                            <div className="left">
                                <h1>Create Payroll Item</h1>
                            </div>
                            <div className="right">
                                <button className="button btn">
                                    <NavLink to='' style={{
                                        textDecoration: 'none',
                                        color: "white"
                                    }}>
                                        Payroll Item
                                    </NavLink>
                                </button>

                            </div>
                        </div>
                        <div className="payNav mb-2">

                            <div className="formHoldering p-3" id='FormInput'>
                                <div className="paymentMethod">
                                    <div className="row">
                                        <div className="col-md-6 right">
                                            <div className="dividedForm">
                                                <h3>Hourly Item</h3>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Item Name</label>
                                                    <input type="text" placeholder='Name' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Item Rate</label>
                                                    <input type="text" placeholder='Item Rate' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Select Payroll Expense Account</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select Account</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="left col-md-6">

                                            <div className="dividedForm">
                                                <h3>Flat Salary Item</h3>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Salary & Wages Name</label>
                                                    <input type="text" placeholder='Name' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Annual Salary & Wages</label>
                                                    <input type="text" placeholder='Item Rate' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Select Payroll Expense Account</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select Account</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-6 right">
                                            <div className="dividedForm">
                                                <h3>Commission Rate</h3>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Commission Name</label>
                                                    <input type="text" placeholder='Name' />
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <div className="select mt-1">
                                                        <label htmlFor="">Commission Rate</label>
                                                        <input type="text" placeholder='Item Rate' />
                                                    </div>
                                                    <div className="select mt-2">
                                                        <label htmlFor="">Select Revenu Item For Commission</label>
                                                        <select
                                                            class="role"
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected>Select Account</option>
                                                            <option value="1">Nigeria</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <div className="select mt-1">
                                                        <label htmlFor="">Commission Amount</label>
                                                        <input type="text" placeholder='Item Rate' />
                                                    </div>
                                                    <div className="select mt-2">
                                                        <label htmlFor="">Select Payroll Expense Account</label>
                                                        <select
                                                            class="role"
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected>Select Account</option>
                                                            <option value="1">Nigeria</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="left col-md-6">

                                            <div className="dividedForm">
                                                <h3>Bonus</h3>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Bonus Name</label>
                                                    <input type="text" placeholder='Name' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Bonus Amount</label>
                                                    <input type="text" placeholder='Item Rate' />
                                                </div>
                                                <div className="select mt-2">
                                                    <label htmlFor="">Select Payroll Expense Account</label>
                                                    <select
                                                        class="role"
                                                        aria-label="Default select example"
                                                    >
                                                        <option selected>Select Account</option>
                                                        <option value="1">Nigeria</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="mt-2"><NavItem /></div> */}

                        <div className="tableSection ">
                            <div className="nav ">
                                <img src={dropArrow} alt="" />
                                <span>Select</span>
                                <li>Delete</li>
                            </div>
                            <div className="p-2">
                                <div id='GeneralTable'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Item Rate</th>
                                                <th scope="col">Salary & Wages Name</th>
                                                <th scope="col">Annual Salary & Wages</th>
                                                <th>Commission Rate</th>
                                                <th>Select Revenu Item For Commission</th>
                                                <th>Bonus Name</th>
                                                <th>Bonus Amount</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">Type of payroll</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                                <td>
                                                    <span className='act'>Active</span>
                                                </td>
                                                <td>
                                                    <span class="dropdown">
                                                        <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Actions
                                                        </span>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" href="#">View</a></li>
                                                            <li><a class="dropdown-item" href="#">Edit</a></li>
                                                            <li><a class="dropdown-item" href="#">Create</a></li>
                                                            <li><a class="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                                <td>
                                                    <span className='inact'>Inactive</span>
                                                </td>
                                                <td>
                                                    <span class="dropdown">
                                                        <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Actions
                                                        </span>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" href="#">View</a></li>
                                                            <li><a class="dropdown-item" href="#">Edit</a></li>
                                                            <li><a class="dropdown-item" href="#">Create</a></li>
                                                            <li><a class="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>100230</td>
                                                <td>Carton Biscuit</td>
                                                <td>
                                                    <span className='act'>Active</span>
                                                </td>
                                                <td>
                                                    <span class="dropdown">
                                                        <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Actions
                                                        </span>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" href="#">View</a></li>
                                                            <li><a class="dropdown-item" href="#">Edit</a></li>
                                                            <li><a class="dropdown-item" href="#">Create</a></li>
                                                            <li><a class="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="paginate">
                                    <nav aria-label="...">
                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                        <span>Displaying 1 - 2 of 2</span>
                                    </nav>
                                    <button className="btn">
                                        <GetAppIcon />
                                        Export as CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ButtomNav />
        </div>
    )

};
const NavItem = () => {
    return (
        <div className="d-flex navHolder">
            <GeneralNavBar name='Create New Employee & List' to='/CreateNewEmployee' />
            <GeneralNavBar name='Create Salary Structue & List' to='/CreateSalarySructure' />
            <GeneralNavBar name='Create Payroll items & List' to='/CreatePayrollitemsList' />
            <GeneralNavBar name='Create Payroll Deduction' to='/PayrollDecductions' />
            <GeneralNavBar name='Leave Approval List' to='/none' />
            <GeneralNavBar name='Process Salary & Processed List' to='/none' />
            <GeneralNavBar name='Time Sheet' to='/EmployeeTimeSheet' />
            <GeneralNavBar name='Payroll Report' to='/ListOfEmployee' />
        </div>
    )
}
export default CreatePayrollitemsList