import React from 'react'
import Sidebar from './Sidebar'
import '../styles/dashboardStyle.scss'
import '../styles/inputs.scss'
import '../styles/budgetDash.scss'
import '../styles/tabelz.scss'
import { Link } from "react-router-dom";
import NavBoard from "../dashboard/NavBoard";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import chart from '../assets/Pie Chart.png'
const ForecastBudget = () => {
  return (
      <div id="HomeDashboard">
          <Sidebar />
          <div id="MainContent" >
              <NavBoard />
              <div id="CustomersDashBoard">
                  <div className='container'>
                      <div className="budgetNavHolder">
                          <div className="upperNav one">
                              <ul>
                                  <Link to='/ForecastBudget/BudgetPage'>
                                      <li className='act'>Financial Tables</li>
                                  </Link>
                                
                              </ul>
                          </div>
                          <div className="upperNav ">
                              <ul>
                                  <Link to='/BudgetPage'>
                                      <li className='act'>Revenue</li>
                                  </Link>
                                  <Link to='/DirectDirectDashBoard'>
                                      <li>Direct Costs</li>
                                  </Link>
                                  <Link to='/Personnel'>
                                      <li>Personnel</li>
                                  </Link>
                                  <Link to='/Expenses'>
                                      <li>Expenses</li>
                                  </Link>
                                 
                                  <Link to='/Financin'>
                                      <li>Other Income</li>
                                  </Link>Capital Income
                                  <Link to='/Financin'>
                                      <li>Capital Income</li>
                                  </Link>
                                  <Link to='/Taxes'>
                                      <li>Tax Provisions</li>
                                  </Link>
                                  <Link to='/Dividends'>
                                      <li>Dividends</li>
                                  </Link>
                                  <Link to='/CashFlowsAssumptions'>
                                      <li>Cash Flows Assumptions</li>
                                  </Link>

                                  

                              </ul>
                          </div>
                      </div>
                      <div className="chart">
                          <div className="chartNav">
                              <h3>Revenue</h3>
                              <button>Hide Chart</button>
                          </div>
                          <div className="chartHolder">
                              <div className="bar mt-5">
                                  {/* <Chart /> */}
                                  <div className="row">
                                      <div className="col-md-6">
                                          <img src={chart} alt="" />
                                      </div>
                                      <div className="col-md-6 d-flex" style={{
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                      }}>
                                          <div className="text">
                                              <h3>Primary Text</h3>
                                              <h1>5.987,34</h1>
                                          </div>
                                          <div className="spanz d-flex gap-3">
                                              <div className="d-flex">
                                                  <span style={{
                                                      background: 'red',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,
                                                      
                                                  }}></span>
                                                  <p>Product 1</p>
                                              </div>

                                              <div className="d-flex">
                                                  <span style={{
                                                      background: '#F3722C',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,

                                                  }}></span>
                                                  <p>Product 2</p>
                                              </div>

                                              <div className="d-flex">
                                                  <span style={{
                                                      background: '#F8961E',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,

                                                  }}></span>
                                                  <p>Product 3</p>
                                              </div>

                                              
                                          </div>
                                          <div className="d-flex gap-3">
                                              <div className="d-flex">
                                                  <span style={{
                                                      background: '#F9C74F',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,

                                                  }}></span>
                                                  <p>Product 4</p>
                                              </div>

                                              <div className="d-flex">
                                                  <span style={{
                                                      background: '#90BE6D',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,

                                                  }}></span>
                                                  <p>Product 5</p>
                                              </div>
                                              <div className="d-flex">
                                                  <span style={{
                                                      background: '#2D9CDB',
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 5,
                                                      marginRight: 5,
                                                      marginLeft: 5,
                                                      marginTop: 5,
                                                      padding: 5,
                                                      borderRadius: 100,

                                                  }}></span>
                                                  <p>Product 6</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="table">
                          <div className="addBtn">
                              <button>Add Revenue Stream</button>
                              <div className="switchs d-flex">
                                  <span>Monthly</span>
                                  <ToggleOffIcon className="icon" />
                                  <span>Annual</span>
                              </div>
                          </div>
                          <div id='GeneralTable'>
                              <table class="table">
                                  <thead>
                                      <tr>

                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>Jan ‘22</td>
                                          <td>Feb ‘22</td>
                                          <td>Mar ‘22</td>
                                          <td>Apr ‘22</td>
                                          <td>May ‘22</td>
                                          <td></td>
                                          <td></td>
                                      </tr>
                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>
                                              <span class="dropdown">
                                                  <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Actions
                                                  </span>
                                                  <ul class="dropdown-menu">
                                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                                      <li><a class="dropdown-item" href="#">Another action</a></li>
                                                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                  </ul>
                                              </span>
                                          </td>
                                      </tr>

                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>
                                              <span class="dropdown">
                                                  <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Actions
                                                  </span>
                                                  <ul class="dropdown-menu">
                                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                                      <li><a class="dropdown-item" href="#">Another action</a></li>
                                                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                  </ul>
                                              </span>
                                          </td>
                                      </tr>

                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>
                                              <span class="dropdown">
                                                  <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Actions
                                                  </span>
                                                  <ul class="dropdown-menu">
                                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                                      <li><a class="dropdown-item" href="#">Another action</a></li>
                                                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                  </ul>
                                              </span>
                                          </td>
                                      </tr>

                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>
                                              <span class="dropdown">
                                                  <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Actions
                                                  </span>
                                                  <ul class="dropdown-menu">
                                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                                      <li><a class="dropdown-item" href="#">Another action</a></li>
                                                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                  </ul>
                                              </span>
                                          </td>
                                      </tr>

                                      <tr>
                                          <td className='xl'><ArrowRightIcon />Revenue</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>₦60,000.00</td>
                                          <td>
                                              <span class="dropdown">
                                                  <span class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      Actions
                                                  </span>
                                                  <ul class="dropdown-menu">
                                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                                      <li><a class="dropdown-item" href="#">Another action</a></li>
                                                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                  </ul>
                                              </span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                  <div className="footer container">
                      <p>© Copyright Accounting Saas.</p>
                      <ul>
                          <li>Accounting Saas</li>
                          <li>Terms & conditions</li>
                          <li>Privacy Policy</li>
                      </ul>
                  </div>
              </div>
          </div>

      </div>
  )
}

export default ForecastBudget