import '../styles/list.scss'
import '../styles/generalTabel.scss'
import UserNavbar from '../usersdashboard/UserNavbar'
import ButtomNav from "../component/ButtomNav";
import Helper from '../component/Helper';

const SalesTaxItem = () => {
    return (
        <div>
            <UserNavbar />
            <div id="ListUsers">
                <div className="container">
                    <div className="main ">
                        <div className="header">
                            <div className="left">
                                <h1>Create Sales Iteam</h1>
                            </div>
                            <div className="right">
                                {/* <div className="left">
                                    <button className='btn'>
                                        Allocate Items Receipt
                                    </button>
                                </div> */}
                                {/* <button className="button btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Create New Item Bundle
                                </button> */}

                            </div>
                        </div>
                        <div id="ListUsers" className="">
                            <Helper />
                        </div>
                        <div className="container" id='FormInput'>
                            <div className="formHolderContact">

                                <div className="row mt-3" >
                                    <div className="col-md-6 right">

                                        <div className="select">
                                            <label>Type</label>
                                            <select class="role" aria-label="Default select example">
                                                <option selected>Sub-Item</option>
                                                <option value="1">Nigeria</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <span>Use to calaculate a single sales tax at a specific rate that you pay to a single agency.</span>
                                        
                                       

                                        <div className="PurchaseDetails">
                                            <div className=" mt-2">
                                                <label htmlFor="">Tax Rate of (%)</label>
                                                <div className="email-input">
                                                    <input type="text" placeholder='₦0.00' />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="PurchaseDetails">
                                            <div className="select mt-2">
                                                <label>Tax Agency(vendor that you collect for) </label>
                                                <select class="role" aria-label="Default select example">
                                                    <option selected></option>
                                                    <option value="1">Nigeria</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 left">
                                        <div className="">
                                            <label htmlFor="">Sales Tax Name</label>
                                            <div className="email-input">
                                                <input type="text" placeholder='Enter item number' />
                                            </div>
                                        </div>
                                        <div className="Description mt-3 mb-5">
                                            <h1> Description on Purchase</h1>
                                            <textarea name="" id="" cols="30" rows="10"></textarea>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav />
        </div>
    )
}
export default SalesTaxItem