import React, { useState } from 'react'
import '../styles/login.scss'
import img from "../assets/Logo.png";
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
const SuperAdminLogin = () => {
    const [formData, setFormData] = useState({

        email: '',
        password: ''
    });
    const navigate = useNavigate()
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = e => {
        e.preventDefault();
        if (formData.email === '' || formData.password === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required'
            })
        } else if (!formData.email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email'
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Login Successfully!',
                text: 'Thank you can now proceed to the dashboard'
            })
            navigate('/HomeDashboard')
        }
    };
    return (
        <div id='Login' onSubmit={handleSubmit}>
            <div className="loginHolder">
                <div className="img">
                    <img src={img} alt="" />
                </div>
                <div className="titleHolderLogin">
                    <h1> Welcome back Admin</h1>
                    <p>Log in to your Accounting Saas Account</p>
                </div>
                <div className="formHolder">
                    <form>
                        <div className="mail">
                            <label>Email Address</label>
                            <input type="text" placeholder='Email Address' name="email"
                                value={formData.email}
                                onChange={handleChange} />
                        </div>
                        <div className="ForgotPassword">
                            <p><Link to='/forgot-password'>Forgot Password?</Link></p>
                        </div>
                        <div className="pass">
                            <label>Password</label>
                            <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder='Password' />
                        </div>
                        <div className="rememberMe">
                            <input type="checkbox" id="vehicle1" />
                            <span>Remember me</span>
                        </div>
                        <div className="button">

                            <button type="submit">Log in</button>

                        </div>
                        <p className='dont'>Don’t have an account? <Link to='/reg'>Create an account.</Link></p>
                    </form>
                </div>
            </div>
            <div className="tabNav">
                <p>Accounting Saas</p>
                <p>Accounting Saas</p>
                <p>Privacy Policy</p>
            </div>
        </div>
    )
}

export default SuperAdminLogin