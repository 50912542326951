import '../styles/list.scss'
import '../styles/generalTabel.scss'
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import img from '../assets/Youtube video player.png'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar'
import ButtomNav from "../component/ButtomNav";
import Helper from '../component/Helper';

const IteamWht = () => {
    return (
        <div>
            <UserNavbar />
            <div id="ListUsers">
                <div className="container">
                    <div className="main ">
                        <div className="header">
                            <div className="left">
                                <h1>Create Withholding Iteam</h1>
                            </div>
                            <div className="right">
                               

                            </div>
                        </div>
                        <div id="ListUsers" className="">
                            <Helper />
                        </div>
                        <div className="container" id='FormInput'>
                            <div className="formHolderContact">

                                <div className="row mt-3" >
                                    <div className="col-md-6 right">
                                        <div className="select mt-2">
                                            <label>Type</label>
                                            <select class="role" aria-label="Default select example">
                                                <option selected></option>
                                                <option value="1">Nigeria</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <span className='small'>This is a withholding tax deduct at the source by the authorised customers on contract sales</span>
                                       

                                        <div className="select mt-2">
                                            <label>Select the Goverment Revenue Agency</label>
                                            <select class="role" aria-label="Default select example">
                                                <option selected>Sub-Item</option>
                                                <option value="1">Nigeria</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div className="PurchaseDetails">


                                          

                                        </div>
                                    </div>
                                    <div className="col-md-6 left">

                                        <div className="">
                                            <label htmlFor="">Tax Rate (%)/Amount</label>
                                            <div className="email-input">
                                                <input type="text" placeholder='₦0.00' />
                                            </div>
                                        </div>
                                        
                                        <div className="Description mb-5">
                                            <h1> Description</h1>
                                            <textarea name="" id="" cols="30" rows="10"></textarea>
                                        </div>

                                        

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav />
        </div>
    )
}

export default IteamWht