import React, { useState } from 'react'
import NavBoard from '../dashboard/NavBoard'
import Sidebar from './Sidebar'
import edit from '../assets/GroupEdit.png'
import pencile from '../assets/pencil.png'
import add from '../assets/GroupAdd.png'
import { NavLink } from 'react-router-dom'
const ConclusionRecommendations = () => {
    return (
        <div id="HomeDashboard">
            <Sidebar />
            <div id="MainContent" >
                <NavBoard />
                <div id="plan">
                    <h3>Plan write up outlines</h3>
                    <div className="divs">
                        <div className="leftBar">
                            <NavBarDetails to='/PlanDetails' title="I. Introduction" details="• Explanation of the purpose and goals of the budget
• Overview of the company's current financial position"/>

                            <NavBarDetails to='/RevenueProjection' title="II. Revenue Projection" details="• Forecast of revenue from sales, services, and other sources
• Analysis of historical trends and current market conditions
• Identification of growth opportunities and potential risks"/>
                            <NavBarDetails to='/ExpensesProjection' title="III. Expenses Projection" details="• Forecast of expenses, including cost of goods sold, operating expenses, and capital expenditures
• Assessment of the impact of external and internal factors on expenses
• Development of cost reduction strategies"
                            />
                            <NavBarDetails to='/CashFlowB' title="IV. Cash Flow" details="• Forecast of cash inflows and outflows over a specific period (e.g., 12 months)
• Evaluation of the company's liquidity and ability to meet financial obligations
• Identification of opportunities to improve cash flow management" />
                            <NavBarDetails to='/BudgetMonitoringControl' title="V. Budget Monitoring and Control" details="• Establishment of a monitoring and control system for the budget
• Development of performance metrics and key performance indicators
• Implementation of corrective actions and adjustments to the budget" />
                            <NavBarDetails to='/ConclusionRecommendations' title="VI. Conclusion and Recommendations" details="• Summary of the budget projections and analysis
• Recommendations for actions to achieve budget goals and objectives
• Identification of potential risks and challenges that the company should prepare for" />
                            <NavBarDetails to='/Appendix' title="VII. Appendix" details="• Supporting data and charts used in the budget projections and analysis
• Glossary of financial terms
• References and sources used in the research and analysis" />
                            <div className="btn">
                                <button>
                                    <img src={add} alt="" />Create Custom Outline
                                </button>
                            </div>
                        </div>

                        <div className="rigthContents">
                            <Details />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const NavBarDetails = ({ title, details, to }) => {
    const [toggle, setToggle] = useState(false);
    const handleClick = () => {
        setToggle(!toggle);
    };
    return (
        <div className="">
            <ol type="i" style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px',
                margin: '0px',
                border: 'none',
                borderRadius: '0px',
                boxShadow: 'none',
                backgroundColor: 'transparent'
            }}>

                <li className='activate'>

                    <h5 style={{
                        cursor: 'pointer'
                    }} onClick={handleClick}>
                        <NavLink to={to} style={{ textDecoration: "none", color: "#128C7E" }} className={({ isActive }) => isActive ? "act" : undefined
                        }>{title}</NavLink>
                    </h5>
                    {
                        toggle && <>
                            <span>
                                {details}
                            </span>

                        </>

                    }
                </li>


            </ol>


        </div>

    )
}

export const Details = () => {
    const [toggle, setToggle] = useState(true);
    const handleClick = () => {
        setToggle(!toggle);
    };
    return (
        <div>
            {
                toggle && <>
                    <div className="content">
                        <h3>Conclusion and Recommendations <img src={edit} alt="" /></h3>
                        <div className="content_details">
                            <div className="spaceX">
                                <p>. Explantion of the purpose and goals of the budget</p>
                                <p>Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                                <div className="edit">
                                    <button><img src={pencile} alt="" />Edit</button>
                                </div>
                            </div>

                            <div className="spaceX">
                                <p>. Overview of the company’s current financial position</p>
                                <p>Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                                <div className="edit">
                                    <button><img src={pencile} alt="" /> Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }

        </div>
    )
}

export default ConclusionRecommendations