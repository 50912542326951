import '../styles/list.scss'
import '../styles/generalTabel.scss'
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import img from '../assets/Youtube video player.png'
import GetAppIcon from '@mui/icons-material/GetApp';
import dropArrow from '../assets/Vectors.png'
import UserNavbar from '../usersdashboard/UserNavbar'
import ButtomNav from "../component/ButtomNav";
import Helper from '../component/Helper';

const CreateDiscount = () => {
    return (
        <div>
            <UserNavbar />
            <div id="ListUsers">
                <div className="container">
                    <div className="main ">
                        <div className="header">
                            <div className="left">
                                <h1>Create Discount</h1>
                            </div>
                            <div className="right">
                                {/* <div className="left">
                                    <button className='btn'>
                                        Allocate Items Receipt
                                    </button>
                                </div> */}
                                {/* <button className="button btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Create New Item Bundle
                                </button> */}

                            </div>
                        </div>
                        <div id="ListUsers" className="">
                            <Helper />
                        </div>
                        <div className="container" id='FormInput'>
                            <div className="formHolderContact">

                                <div className="row mt-3" >
                                    <div className="col-md-6 right">

                                        <div className="select">
                                            <label>Type of discount</label>
                                            <select class="role" aria-label="Default select example">
                                                <option selected>Sub-Item</option>
                                                <option value="1">Nigeria</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div className="select">
                                            <label><input type="checkbox" style={{ width: 25 }} />Sub of</label>
                                            <select class="role" aria-label="Default select example">
                                                <option selected></option>
                                                <option value="1">Nigeria</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                        <div className="PurchaseDetails">


                                            <div className="select mt-2">
                                                <label>Tax Code</label>
                                                <select class="role" aria-label="Default select example">
                                                    <option selected>Tax Code</option>
                                                    <option value="1">Nigeria</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="PurchaseDetails">
                                            <div className="title">

                                            </div>

                                            <div className=" mt-2">
                                                <label htmlFor="">Account of %</label>
                                                <div className="email-input">
                                                    <input type="text" placeholder='₦0.00' />
                                                </div>
                                            </div>
                                            <span>Discount is applied before sales tax</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 left">
                                        <div className="">
                                            <label htmlFor="">Item Number/Name</label>
                                            <div className="email-input">
                                                <input type="text" placeholder='Enter item number' />
                                            </div>
                                        </div>




                                        <div className="Description mt-3 mb-5">
                                            <h1> Description on Purchase</h1>
                                            <textarea name="" id="" cols="30" rows="10"></textarea>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav />
        </div>
    )
}

export default CreateDiscount