import Helper from "../component/Helper";

const CreateVendor = () => {
  return (
      <div>
          <div className="titleHolder m-4">
              <h1>Create New Vendor</h1>
              <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
          </div>
          <div id="ListUsers" className="">
              <Helper />
          </div>
    </div>
  )
}

export default CreateVendor