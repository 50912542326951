import "../styles/inputs.scss";
import "../styles/in.scss";
import UserNavbar from "../usersdashboard/UserNavbar";
import ButtomNav from "../component/ButtomNav";
import Helper from "../component/Helper";

const RecurrenSchedule = () => {
    return (
        <div>
            <UserNavbar />
            <div id="Invoice">
                <div className="container">
                    <div className="Invoice-container ">
                        <div className="invoice-header">
                            <div className="left">
                                <div className="top">
                                    <h1>Recurring Bank Deposit</h1>
                                </div>
                            </div>

                        </div>
                        <div id="ListUsers" className="">
                            <Helper />
                        </div>
                        <div className="main" id="FormInput">
                            <form action="" id="FormInput">
                                <div className="payment">
                                    <div className="row mt-3">
                                        <div className="paymentMethod">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="dividedForm">
                                                        <div className="">
                                                            <label htmlFor="">Bank Deposit</label>
                                                            <input type="text" placeholder='Template Name'/>
                                                        </div>
                                                        <div className="d-flex gap-1" style={{
                                                            alignItems: 'center'
                                                        }}>
                                                            <span>Create</span>
                                                            <input type="text" style={{
                                                                width: '10%',padding:2}
                                                            }/>
                                                            <span>days in advance</span>
                                                        </div>

                                                        <div className="select mt-2">
                                                            <label htmlFor="">Amount</label>
                                                            <select
                                                                class="role"
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected>Nigerian Naira NGN</option>
                                                                <option value="1">Nigeria</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="left col-md-6">
                                                    <div className="Exchange mt-2">
                                                        <div className="select mt-2">
                                                            <label htmlFor="">Type</label>
                                                            <select
                                                                className="role"
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected>Schedule</option>
                                                                <option value="1">Nigeria</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="interval-container">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="">
                                                            interval
                                                        </label>
                                                        <div className="d-flex gap-2" style={{
                                                            alignItems:'center',
                                                        }}>
                                                            <div className=" mt-2">
                                                                <select
                                                                    className="role"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected>Monthly</option>
                                                                    <option value="1">Nigeria</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                            </div>
                                                            <span>on</span>
                                                            <div className=" mt-2">

                                                                <select
                                                                    className="role"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected>day</option>
                                                                    <option value="1">Nigeria</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                            </div>
                                                            <div className=" mt-2">

                                                                <select
                                                                    className="role"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected>1st</option>
                                                                    <option value="1">Nigeria</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                            </div>
                                                            <span>of every</span>
                                                            <input type="text" placeholder='1' style={{
                                                                padding:2, width:30}
                                                            }/>
                                                            <span>month(s)</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex">
                                                        <div className="">
                                                            <label htmlFor="">
                                                                Start date
                                                            </label>
                                                            <input type="datetime-local"/>
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="">
                                                                End
                                                            </label>
                                                            <input type="datetime-local"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="d-flex mt-3" style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <span>Add funds to this deposit</span>
                                <div className="d-flex"style={{
                                    alignItems: 'center',
                                }}>
                                    <span>Amount are:</span>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            Exclusive of Tax
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div id="GeneralTable">

                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Received From</th>
                                        <th scope="col"> Account</th>
                                        <th scope="col">Description</th>

                                        <th scope="col">Payment Method</th>
                                        <th scope="col">REF No.</th>

                                        <th scope="col">Amount</th>
                                        <th scope="col">Tax</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtomNav />
        </div>
    );
};

export default RecurrenSchedule;

