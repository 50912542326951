import React from 'react'
import Homes from './pages/Homes'
import './styles/Gobal-style.scss'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import ForgotPassword from './auth/ForgotPassword';
import VerifyEmail from './auth/VerifyEmail';
import Register from './auth/Register';
import Otp from './auth/Otp';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import ClientsSupport from './pages/ClientsSupport';
import Online from './pages/Online';
import UserHomePage from './usersdashboard/UserHomePage';
import Settings from './usersdashboard/Settings';
import AddNewCustomer from './usersdashboard/AddNewCustomer';
import Invoice from './usersdashboard/Invoice';
import CustomersDashBoard from "./usersdashboard/CustomersDashBoard";
import ListUsers from "./usersdashboard/ListUsers";
import CustomersSales from "./usersdashboard/CustomersSales";
import CustomerCategories from "./usersdashboard/CustomerCategories";
import CustomerCredit from "./usersdashboard/CustomerCredit";
import CustomerReceipt from "./usersdashboard/CustomerReceipt";
import ListSales from "./usersdashboard/ListSales";
import CustomerWrite from "./usersdashboard/CustomerWrite";
import CustomerInvoices from "./usersdashboard/CustomerInvoices";
import ProcessReceipt from './usersdashboard/ProcessReceipt';
import CustomerWriteOffs from "./usersdashboard/CustomerWriteOffs";
import NewCustomerCategory from './usersdashboard/NewCustomerCategory';
import RecurringInvoice from "./usersdashboard/RecurringInvoice";
import ItemDashboard from './Items/ItemDashboard';
import CreateNewItem from './Items/CreateNewItem';
import CreateNewPhysical from './Items/CreateNewPhysical';
import CreateItemUnchecked from './Items/CreateItemUnchecked ';
import CreateItemChecked from "./Items/CreateItemChecked";
import ItemDashboardCat from "./Items/ItemDashboardCat";
import ItemDashboarList from './Items/ItemDashboarList';
import ListItemBundles from './Items/ListItemBundles';
import AdjustItemOpeningBalances from "./Items/AdjustItemOpeningBalances";
import ItemBundle from './Items/ItemBundle';
import CreateInventoryAdjustment from './Items/CreateInventoryAdjustment';
import ItemReceipt from './Items/ItemReceipt';
import ListOfAccount from './chart/ListOfAccount';
import CreateAnAccount from './chart/CreateAnAccount';
import ChartOfAccountExpenses from './chart/ChartOfAccountExpenses';
import MakeJournalEntries from "./chart/MakeJournalEntries";
import TrialBalance from './chart/TrialBalance';
import UserAddress from "./usersdashboard/UserAddress";
import UserPayment from "./usersdashboard/UserPayment";
import UserNote from "./usersdashboard/UserNote";
import Attachments from './usersdashboard/Attachments';
import AdditionalContactInfo from "./usersdashboard/AdditionalContactInfo";
import CreatePaymentVoucher from "./banking/CreatePaymentVoucher";
import MakeDeposit from './banking/MakeDeposit';
import TransferFunds from './banking/TransferFunds';
import CreateBatchEntryBills from "./banking/CreateBatchEntryBills";
import CreateNewEmployee from './employee/CreateNewEmployee';
import EmergencyContact from './employee/EmergencyContact';
import SalaryWages from "./employee/SalaryWages";
import EmploymentDetails from "./employee/EmploymentDetails";
import LeaveOfAbsence from "./employee/LeaveOfAbsence";
import Termination from "./employee/Termination";
import ListOfEmployee from "./employee/ListOfEmployee";
import EmployeeTimeSheet from './employee/EmployeeTimeSheet';
import CreateSalarySructure from "./employee/CreateSalarySructure";
import CreateSructure from './employee/CreateSructure';
import HomeDashboard from "./dashboard/HomeDashboard";
import Team from "./dashboard/Team";
import Subscribers from "./dashboard/Subscribers";
import Companies from "./dashboard/Companies";
import RegisteredUser from './dashboard/RegisteredUser';
import RealizedLosses from './Report/RealizedLosses';
import UnrealizedLosses from './Report/UnrealizedLosses';
import ExpensesSummary from "./Report/ExpensesSummary";
import IncomeCustomerDetail from "./Report/IncomeCustomerDetail";
import IncomeCustomerSummary from "./Report/IncomeCustomerSummary";
import ProfitLossComparison from "./Report/ProfitLossComparison";
import PageOne from "./Report/PageOne";
import HrPage from './employee/HrPage';
import BudgetPage from "./Finanicial/BudgetPage";
import DirectCosts from "./Finanicial/DirectCosts";
import Personnel from "./Finanicial/Personnel";
import Assets from "./Finanicial/Assets";
import CashFlowsAssumptions from "./Finanicial/CashFlowsAssumptions";
import Dividends from "./Finanicial/Dividends";
import Taxes from "./Finanicial/Taxes";
import Expenses from "./Finanicial/Expenses";
import Financing from "./Finanicial/Financing";
import ProfitLoss from "./Finanicial/ProfitLoss";
import BalanceSheet from "./Finanicial/BalanceSheet";
import CashFlow from './Finanicial/CashFlow';
import CreateRevenueStream from './Finanicial/CreateRevenueStream';
import CreateDirectCost from './Finanicial/CreateDirectCost';
import CreateExpenses from './Finanicial/CreateExpenses';
import CreateAsset from './Finanicial/CreateAsset';
import SetTaxRate from "./Finanicial/SetTaxRate";
import CreateDividend from './Finanicial/CreateDividend';
import CreateLoan from "./Finanicial/CreateLoan";
import CreateInvestment from './Finanicial/CreateInvestment';
import AccountingSettings from "./settings/AccountingSettings";
import BillsSettings from "./settings/BillsSettings";
import GeneralSettings from "./settings/GeneralSettings";
import ItemsInventorySettings from "./settings/ItemsInventorySettings";
import PaymentsSettinhs from "./settings/PaymentsSettinhs";
import PayrollEmployees from "./settings/PayrollEmployees";
import ReportsGraphs from "./settings/ReportsGraphs";
import SalesCustomers from "./settings/SalesCustomers"
import SalesTaxSettings from "./settings/SalesTaxSettings";
import ManageCurrencies from "./settings/ManageCurrencies";
import FinancialPositionbyClass from "./FINANCIALPOSITION/FinancialPositionbyClass";
import PageTwo from './FINANCIALPOSITION/PageTwo';
import PageThree from "./FINANCIALPOSITION/PageThree";
import PageFour from "./FINANCIALPOSITION/PageFour";
import PageFive from "./FINANCIALPOSITION/PageFive";
import PageSix from "./FINANCIALPOSITION/PageSix";
import PageSeven from "./FINANCIALPOSITION/PageSeven";
import AverageAllTransactions from "./CUSTOMER RECEIVABLES/AverageAllTransactions";
import AveragePageTwo from "./CUSTOMER RECEIVABLES/AveragePageTwo";
import AveragePageThree from "./CUSTOMER RECEIVABLES/AveragePageThree";
import CustomerPhoneList from "./CUSTOMER RECEIVABLES/CustomerPhoneList";
import TransactionCustomer from "./CUSTOMER RECEIVABLES/TransactionCustomer";
import AgingDetail from './CUSTOMER RECEIVABLES/AgingDetail';
import RAgingSummary from "./CUSTOMER RECEIVABLES/RAgingSummary";
import CustomerBalanceSummary from './CUSTOMER RECEIVABLES/CustomerBalanceSummary';
import CustomerBalanceDetail from './CUSTOMER RECEIVABLES/CustomerBalanceDetail';
import CollectionsReport from "./CUSTOMER RECEIVABLES/CollectionsReport";
import OpenInvoices from './CUSTOMER RECEIVABLES/OpenInvoices';
import CheckDetail from "./banking/CheckDetail";
import DepositDetail from './banking/DepositDetail';
import InventoryValuationDetail from "./INVENTORY/InventoryValuationDetail";
import PhysicalInventoryWorksheet from "./INVENTORY/PhysicalInventoryWorksheet";
import InventoryStockItem from './INVENTORY/InventoryStockItem';
import InventoryValuationSummary from './INVENTORY/InventoryValuationSummary';
import ItemListing from './INVENTORY/ItemListing';
import TermsListing from "./INVENTORY/TermsListing";
import AccountListing from "./Others/AccountListing";
import AdjustingJournalEntries from './Others/AdjustingJournalEntries';
import AdjustedTrialBalance from './Others/AdjustedTrialBalance';
import AuditTrail from './Others/AuditTrail';
import FixedAssetListing from "./Others/FixedAssetListing";
import GeneralLedger from './Others/GeneralLedger';
import IncomeTaxPreparation from './Others/IncomeTaxPreparation';
import Journal from "./Others/Journal";
import ItemPriceList from "./Others/ItemPriceList";
import TransactionDetailsAccount from './Others/TransactionDetailsAccount';
import TrialBalances from "./Others/TrialBalances";
import VoidedDeletedTransactionsSummary from "./Others/VoidedDeletedTransactionsSummary";
import ToDoNotes from "./Others/ToDoNotes";
import HelpCenter from "./Others/HelpCenter";
import EstimatebyJob from "./SALES/EstimatebyJob";
import ActualsSummary from './SALES/ActualsSummary';
import JobProfitabilitySummary from "./SALES/JobProfitabilitySummary";
import OpenSalesOrdersbyCustomer from './SALES/OpenSalesOrdersbyCustomer';
import OpenSalesOrderstem from './SALES/OpenSalesOrderstem';
import SalesCustomerDetail from './SALES/SalesCustomerDetail';
import SalesCustomerSummaryValues from './SALES/SalesCustomerSummaryValues ';
import SalesbyRepSummary from './SALES/SalesbyRepSummary';
import SalesRepSummaryTwo from "./SALES/SalesRepSummaryTwo";
import SalesRepSummaryThree from "./SALES/SalesRepSummaryThree";
import SalesItemSummaryFour from "./SALES/SalesItemSummaryFour";
import SalesbitemDetail from "./SALES/SalesbitemDetail";
import OpenPurchaseOrders from './VENDORS/OpenPurchaseOrders';
import OpenPurchaseOrdersbyJob from './VENDORS/OpenPurchaseOrdersbyJob';
import PurchasesbyItemDetail from './VENDORS/PurchasesbyItemDetail';
import PurchasesbyItemSummary from './VENDORS/PurchasesbyItemSummary';
import PurchasesbyVendorDetail from './VENDORS/PurchasesbyVendorDetail';
import SalesTaxLiability from './VENDORS/SalesTaxLiability';
import TransactionListbyVendor from './VENDORS/TransactionListbyVendor';
import UnpaidBillsDetail from './VENDORS/UnpaidBillsDetail';
import ARAgingSummaryValuesinHC from './VENDORS/ARAgingSummaryValuesinHC';
import VendorARAgingDetails from './VENDORS/VendorARAgingDetails';
import VendorSummaryValuesHC from './VENDORS/VendorSummaryValuesHC';
import VendorBalanceDetail from './VENDORS/VendorBalanceDetail';
import VendorContactList from "./VENDORS/VendorContactList";
import ChartOfAccount from './ALL REPORT/ChartOfAccount';
import CashFlows from './ALL REPORT/CashFlows';
import FinancialPosition from './ALL REPORT/FinancialPosition';
import Equity from './ALL REPORT/Equity';
import ProfitOrLoss from './ALL REPORT/ProfitOrLoss';
import ProfitOrLossFormat from './ALL REPORT/ProfitOrLossFormat';
import EquityFormat from "./ALL REPORT/EquityFormat";
import FinancialPositionFormat from "./ALL REPORT/FinancialPositionFormat";
import CashFlowFormat from "./ALL REPORT/CashFlowFormat";
import Reports from './ALL REPORT/Reports';
import ProfitLossPrevYearComparison from "./Report/ProfitLossPrevYearComparison";
import ProfitandLossYTDComparison from './Report/ProfitandLossYTDComparison';
import PageThreePro from "./Report/PageThreePro";
import PageFourPro from './Report/PageFourPro';
import ProfitandLossDetail from "./Report/ProfitandLossDetail";
import ProfitLossPro from './Report/ProfitLossPro';
import SuperAdminLogin from "./auth/SuperAdminLogin";
import PartnerList from "./dashboard/PartnerList";
import Revenue from './dashboard/Revenue';
import Transactions from './dashboard/Transactions';
import BillingPLan  from "./dashboard/BillingPLan";
import AddSalesRep from "./usersdashboard/AddSalesRep";
import SalesOrderEstimate from './usersdashboard/SalesOrderEstimate';
import CreditNote from './usersdashboard/CreditNote';
import NewCustomerReceipt from './usersdashboard/NewCustomerReceipt';
import CreateReceivable from './usersdashboard/CreateReceivable';
import CreateDiscount from './Items/CreateDiscount';
import CreateSalesTax from "./Items/CreateSalesTax";
import SalesTaxItem from "./Items/SalesTaxItem";
import IteamWht from "./Items/IteamWht";
import ItemCat from "./Items/ItemCat";
import CreatePayrollitemsList from './employee/CreatePayrollitemsList';
import PayrollDecductions from "./employee/PayrollDecductions";
import SalartStucturess from "./employee/SalartStucturess";
import Income from "./chart/Income";
import RecurringBankDeposit from "./banking/RecurringBankDeposit";
import RecurrenSchedule from "./banking/RecurrenSchedule";
import BudgetsSettings from './BUDGETDASHBOARD/BudgetsSettings';
import ForecastBudget from './BUDGETDASHBOARD/ForecastBudget';
import PlanDetails from './BUDGETDASHBOARD/PlanDetails';
import DirectDirectDashBoard from './BUDGETDASHBOARD/DirectDirectDashBoard';
import PlanAnalysis from './BUDGETDASHBOARD/PlanAnalysis';
import CreateTax from './BUDGETDASHBOARD/CreateTax';
import RevenueProjection from './BUDGETDASHBOARD/RevenueProjection';
import ExpensesProjection from './BUDGETDASHBOARD/ExpensesProjection';
import CashFlowB from './BUDGETDASHBOARD/CashFlowB';
import BudgetMonitoringControl from './BUDGETDASHBOARD/BudgetMonitoringControl';
import ConclusionRecommendations from './BUDGETDASHBOARD/ConclusionRecommendations';
import Appendix from './BUDGETDASHBOARD/Appendix';
import CreateNewVendor from "../src/VENDORS/CreateNewVendor"
import BankDetails from "../src/./VENDORS/BankDetails";
import AdditionalContact from "../src/./VENDORS/AdditionalContact";
import Payment from "../src/./VENDORS/Payment";
import Notes from "../src/./VENDORS/Notes";
import Upload from "../src/./VENDORS/Upload";
import Address from "../src/./VENDORS/Address";
import ProcessVendorAdjustment from "../src/./VENDORS/ProcessVendorAdjustment";
import ProcessVendorReturn from "../src/./VENDORS/ProcessVendorReturn";
import ProcessVendorInvoice from "../src/./VENDORS/ProcessVendorInvoice";
import NewVendorCategory from "../src/./VENDORS/NewVendorCategory";
import AdjustVendorOpeningBalances from "../src//VENDORS/AdjustVendorOpeningBalances";
import ProcessPayment from "../src/./VENDORS/ProcessPayment";
import VendorPayment from "../src/./VENDORS/VendorPayment";
import VendorReturns from "../src/./VENDORS/VendorReturns";
import VendorInvoices from "../src/./VENDORS/VendorInvoices";
import VendorPurchaseOrders from "../src/./VENDORS/VendorPurchaseOrders";
import VendorCategories from "../src/./VENDORS/VendorCategories";
import ListVendors from "../src/./VENDORS/ListVendors";
import Wat from "../src/./VENDORS/Wat";
import VendorDashboard from "../src/./VENDORS/VendorDashboard";
// import DirectDirectDashBoard from '../src/./BUDGETDASHBOARD/DirectDirectDashBoard';
import VendorAdjustments from "../src/./VENDORS/VendorAdjustments";
const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path='/post' element={<SinglePage />} />
          <Route path='/contact' element={<ContactUsPage />} />
         */}
          <Route path='/Appendix' element={<Appendix />} />
          <Route path='/ConclusionRecommendations' element={<ConclusionRecommendations />} />
          <Route path='/BudgetMonitoringControl' element={<BudgetMonitoringControl />} />
          <Route path='/CreateTax' element={<CreateTax />} />
          <Route path='/CashFlowB' element={<CashFlowB />} />
          <Route path='/ExpensesProjection' element={<ExpensesProjection />} />
          <Route path='/RevenueProjection' element={<RevenueProjection />} />
          <Route path='/PlanAnalysis' element={<PlanAnalysis />} />
          <Route path='/DirectDirectDashBoard' element={<DirectDirectDashBoard/>} />
          <Route path='/PlanDetails' element={<PlanDetails />} />
          <Route path='/ForecastBudget' element={<ForecastBudget />} />
          <Route path='/BudgetsSettings' element={<BudgetsSettings />} />
          <Route path='/RecurrenSchedule' element={<RecurrenSchedule />} />
          <Route path='/RecurringBankDeposit' element={<RecurringBankDeposit />} />
          <Route path='/Income' element={<Income />} />
          <Route path='/SalartStucturess' element={<SalartStucturess />} />
          <Route path='/PayrollDecductions' element={<PayrollDecductions />} />
          <Route path='/CreatePayrollitemsList' element={<CreatePayrollitemsList />} />
          <Route path='/ItemCat' element={<ItemCat />} />
          <Route path='/IteamWht' element={<IteamWht />} />
          {/* <Route path='/Wat' element={<Wat />} /> */}
          <Route path='/SalesTaxItem' element={<SalesTaxItem />} />
          <Route path='/CreateSalesTax' element={<CreateSalesTax />} />
          <Route path='/CreateDiscount' element={<CreateDiscount />} />
          <Route path='/CreateReceivable' element={<CreateReceivable />} />
          <Route path='/NewCustomerReceipt' element={<NewCustomerReceipt />} />
          <Route path='/CreditNote' element={<CreditNote />} />
          <Route path='/SalesOrderEstimate' element={<SalesOrderEstimate />} />
          <Route path='/AddSalesRep' element={<AddSalesRep />} />
          <Route path='/BillingPLan' element={<BillingPLan />} />
          <Route path='/Transactions' element={<Transactions />} />
          <Route path='/Revenue' element={<Revenue />} />
          <Route path='/PartnerList' element={<PartnerList />} />
          <Route path='/HrPage' element={<HrPage />} />
          <Route path='/ProfitLossPro' element={<ProfitLossPro />} />
          <Route path='/ProfitandLossDetail' element={<ProfitandLossDetail />} />
          <Route path='/ProfitandLossYTDComparison' element={<ProfitandLossYTDComparison />} />
          <Route path='/ProfitLossPrevYearComparison' element={<ProfitLossPrevYearComparison />} />
          <Route path='/Reports' element={<Reports />} />
          <Route path='/CashFlowFormat' element={<CashFlowFormat />} />
          <Route path='/FinancialPositionFormat' element={<FinancialPositionFormat />} />
          <Route path='/EquityFormat' element={<EquityFormat />} />
          <Route path='/ProfitOrLossFormat' element={<ProfitOrLossFormat />} />
          <Route path='/ProfitOrLoss' element={<ProfitOrLoss />} />
          <Route path='/Equity' element={<Equity />} />
          <Route path='/FinancialPosition' element={<FinancialPosition />} />
          <Route path='/CashFlows' element={<CashFlows />} />
          <Route path='/ChartOfAccount' element={<ChartOfAccount />} />
          <Route path='/VendorContactList' element={<VendorContactList />} />
          <Route path='/VendorBalanceDetail' element={<VendorBalanceDetail />} />
          <Route path='/VendorSummaryValuesHC' element={<VendorSummaryValuesHC />} />
          <Route path='/VendorARAgingDetails' element={<VendorARAgingDetails />} />
          <Route path='/ARAgingSummaryValuesinHC' element={<ARAgingSummaryValuesinHC />} />
          <Route path='/UnpaidBillsDetail' element={<UnpaidBillsDetail />} />
          <Route path='/TransactionListbyVendor' element={<TransactionListbyVendor />} />
          <Route path='/SalesTaxLiability' element={<SalesTaxLiability />} />
          <Route path='/PurchasesbyVendorDetail' element={<PurchasesbyVendorDetail />} />
          <Route path='/PurchasesbyItemSummary' element={<PurchasesbyItemSummary/>} />
          <Route path='/PurchasesbyItemDetail' element={<PurchasesbyItemDetail />} />
          <Route path='/OpenPurchaseOrdersbyJob' element={<OpenPurchaseOrdersbyJob />} />
          <Route path='/OpenPurchaseOrders' element={<OpenPurchaseOrders />} />
          <Route path='/SalesbitemDetail' element={<SalesbitemDetail />} />
          <Route path='/SalesItemSummaryFour' element={<SalesItemSummaryFour />} />
          <Route path='/SalesRepSummaryThree' element={<SalesRepSummaryThree />} />
          <Route path='/SalesRepSummaryTwo' element={<SalesRepSummaryTwo />} />
          <Route path='/SalesbyRepSummary' element={<SalesbyRepSummary />} />
          <Route path='/SalesCustomerSummaryValues' element={<SalesCustomerSummaryValues />} />
          <Route path='/SalesCustomerDetail' element={<SalesCustomerDetail />} />
          <Route path='/OpenSalesOrderstem' element={<OpenSalesOrderstem />} />
          <Route path='/OpenSalesOrdersbyCustomer' element={<OpenSalesOrdersbyCustomer />} />
          <Route path='/JobProfitabilitySummary' element={<JobProfitabilitySummary />} />
          <Route path='/ActualsSummary' element={<ActualsSummary />} />
          <Route path='/EstimatebyJob' element={<EstimatebyJob />} />
          <Route path='/HelpCenter' element={<HelpCenter />} />
          <Route path='/ToDoNotes' element={<ToDoNotes />} />
          <Route path='/VoidedDeletedTransactionsSummary' element={<VoidedDeletedTransactionsSummary />} />
          <Route path='/TrialBalances' element={<TrialBalances />} />
          <Route path='/TransactionDetailsAccount' element={<TransactionDetailsAccount />} />
          <Route path='/ItemPriceList' element={<ItemPriceList />} />
          <Route path='/Journal' element={<Journal />} />
          <Route path='/IncomeTaxPreparation' element={<IncomeTaxPreparation />} />
          <Route path='/GeneralLedger' element={<GeneralLedger />} />
          <Route path='/FixedAssetListing' element={<FixedAssetListing />} />
          <Route path='/AuditTrail' element={<AuditTrail />} />
          <Route path='/AdjustedTrialBalance' element={<AdjustedTrialBalance />} />
          <Route path='/AdjustingJournalEntries' element={<AdjustingJournalEntries />} />
          <Route path='/AccountListing' element={<AccountListing />} />
          <Route path='/TermsListing' element={<TermsListing />} />
          <Route path='/ItemListing' element={<ItemListing />} />
          <Route path='/InventoryValuationSummary' element={<InventoryValuationSummary />} />
          <Route path='/InventoryStockItem' element={<InventoryStockItem />} />
          <Route path='/PhysicalInventoryWorksheet' element={<PhysicalInventoryWorksheet />} />
          <Route path='/InventoryValuationDetail' element={<InventoryValuationDetail />} />
          <Route path='/DepositDetail' element={<DepositDetail />} />
          <Route path='/CheckDetail' element={<CheckDetail />} />
          <Route path='/OpenInvoices' element={<OpenInvoices />} />
          <Route path='/CollectionsReport' element={<CollectionsReport />} />
          <Route path='/CustomerBalanceDetail' element={<CustomerBalanceDetail />} />
          <Route path='/CustomerBalanceSummary' element={<CustomerBalanceSummary />} />
          <Route path='/RAgingSummary' element={<RAgingSummary />} />
          <Route path='/AgingDetail' element={<AgingDetail />} />
          <Route path='/TransactionCustomer' element={<TransactionCustomer />} />
          <Route path='/CustomerPhoneList' element={<CustomerPhoneList />} />
          <Route path='/AveragePageThree' element={<AveragePageThree />} />
          <Route path='/AveragePageTwo' element={<AveragePageTwo />} />
          <Route path='/AverageAllTransactions' element={<AverageAllTransactions />} />
          <Route path='/PageSeven' element={<PageSeven />} />
          <Route path='/PageSix' element={<PageSix />} />
          <Route path='/PageFive' element={<PageFive />} />
          <Route path='/PageFour' element={<PageFour />} />
          <Route path='/PageThree' element={<PageThree />} />
          <Route path='/PageTwo' element={<PageTwo />} />
          <Route path='/FinancialPositionbyClass' element={<FinancialPositionbyClass />} />
          <Route path='/ManageCurrencies' element={<ManageCurrencies />} />
          <Route path='/SalesTaxSettings' element={<SalesTaxSettings />} />
          <Route path='/SalesCustomers' element={<SalesCustomers />} />
          <Route path='/ReportsGraphs' element={<ReportsGraphs />} />
          <Route path='/PayrollEmployees' element={<PayrollEmployees />} />
          <Route path='/PaymentsSettings' element={<PaymentsSettinhs />} />
          <Route path='/ItemsInventorySettings' element={<ItemsInventorySettings />} />
          <Route path='/GeneralSettings' element={<GeneralSettings />} />
          <Route path='/BillsSettings' element={<BillsSettings />} />
          <Route path='/AccountingSettings' element={<AccountingSettings />} />
          <Route path='/CreateInvestment' element={<CreateInvestment />} />
          <Route path='/CreateLoan' element={<CreateLoan />} />
          <Route path='/CreateDividend' element={<CreateDividend />} />
          <Route path='/SetTaxRate' element={<SetTaxRate />} />
          <Route path='/CreateAsset' element={<CreateAsset />} />
          <Route path='/CreateExpenses' element={<CreateExpenses />} />
          <Route path='/CreateDirectCost' element={<CreateDirectCost />} />
          <Route path='/CreateRevenueStream' element={<CreateRevenueStream />} />
          <Route path='/CashFlow' element={<CashFlow />} />
          <Route path='/BalanceSheet' element={<BalanceSheet />} />
          <Route path='/ProfitLoss' element={<ProfitLoss />} />
          <Route path='/Financin' element={<Financing />} />
          <Route path='/Expenses' element={<Expenses />} />
          <Route path='/Taxes' element={<Taxes />} />
          <Route path='/Dividends' element={<Dividends />} />
          <Route path='/CashFlowsAssumptions' element={<CashFlowsAssumptions />} />
          <Route path='/Assets' element={<Assets />} />
          <Route path='/Personnel' element={<Personnel />} />
          <Route path='/DirectCosts' element={<DirectCosts />} />
          <Route path='/BudgetPage' element={<BudgetPage />} />
          <Route path='/PageThreePro' element={<PageThreePro />} />
          <Route path='/PageFourPro' element={<PageFourPro />} />
          <Route path='/ProfitLossComparisonOne' element={<PageOne />} />
          <Route path='/ProfitLossComparison' element={<ProfitLossComparison />} />
          <Route path='/IncomeCustomerSummary' element={<IncomeCustomerSummary />} />
          <Route path='/IncomeCustomerDetail' element={<IncomeCustomerDetail />} />
          <Route path='/ExpensesSummary' element={<ExpensesSummary />} />
          <Route path='/Unrealized' element={<UnrealizedLosses />} />
          <Route path='/RealizedLosses' element={<RealizedLosses />} />
          <Route path='/RegisteredUser' element={<RegisteredUser />} />
          <Route path='/Companies' element={<Companies />} />
          <Route path='/Subscribers' element={<Subscribers />} />
          <Route path='/Teams' element={<Team />} />
          <Route path='/HomeDashboard' element={<HomeDashboard />} />
          <Route path='/CreateSructure' element={<CreateSructure />} />
          <Route path='/CreateSalarySructure' element={<CreateSalarySructure />} />
          <Route path='/EmployeeTimeSheet' element={<EmployeeTimeSheet />} />
          <Route path='/ListOfEmployee' element={<ListOfEmployee />} />
          <Route path='/Termination' element={<Termination />} />
          <Route path='/LeaveOfAbsence' element={<LeaveOfAbsence />} />
          <Route path='/EmploymentDetails' element={<EmploymentDetails />} />
          <Route path='/SalaryWages' element={<SalaryWages />} />
          <Route path='/EmergencyContact' element={<EmergencyContact />} />
          <Route path='/CreateNewEmployee' element={<CreateNewEmployee />} />
          <Route path='/CreateBatchEntryBills' element={<CreateBatchEntryBills />} />
          <Route path='/TransferFunds' element={<TransferFunds />} />
          <Route path='/MakeDeposit' element={<MakeDeposit />} />
          <Route path='/CreatePaymentVoucher' element={<CreatePaymentVoucher />} />
          <Route path='/Attachments' element={<Attachments />} />
          <Route path='/UserNote' element={<UserNote />} />
          <Route path='/UserPayment' element={<UserPayment />} />
          <Route path='/UserAdditionalContact' element={<AdditionalContactInfo />} />
          <Route path='/UserAddress' element={<UserAddress />} />
          <Route path='/TrialBalance' element={<TrialBalance />} />
          <Route path='/MakeJournalEntries' element={<MakeJournalEntries />} />
          <Route path='/ChartOfAccountExpenses' element={<ChartOfAccountExpenses />} />
          <Route path='/CreateAnAccount' element={<CreateAnAccount />} />
          <Route path='/ListOfAccount' element={<ListOfAccount />} />
          <Route path='/ItemReceipt' element={<ItemReceipt />} />
          <Route path='/CreateInventoryAdjustment' element={<CreateInventoryAdjustment />} />
          <Route path='/ItemBundle' element={<ItemBundle />} />
          <Route path='/AdjustItemOpeningBalances' element={<AdjustItemOpeningBalances />} />
          <Route path='/ListItemBundles' element={<ListItemBundles />} />
          <Route path='/ItemDashboarList' element={< ItemDashboarList />} />
          <Route path='/ItemDashboardCat' element={< ItemDashboardCat />} />
          <Route path='/CreateItemChecked' element={< CreateItemChecked />} />
          <Route path='/CreateItemUnchecked' element={< CreateItemUnchecked />} />
          <Route path='/CreateNewPhysical' element={< CreateNewPhysical />} />
          <Route path='/CreateNewItem' element={< CreateNewItem />} />
          <Route path='/ItemDashboard' element={< ItemDashboard />} />
          <Route path='/ProcessVendorAdjustment' element={< ProcessVendorAdjustment />} />
          <Route path='/ProcessVendorReturn' element={< ProcessVendorReturn />} />
          <Route path='/ProcessVendorInvoice' element={< ProcessVendorInvoice />} />
          <Route path='/NewVendorCategory' element={< NewVendorCategory />} />
          <Route path='/AdjustVendorOpeningBalances' element={< AdjustVendorOpeningBalances />} />
          <Route path='/ProcessPayment' element={< ProcessPayment />} />
          <Route path='/VendorPayment' element={< VendorPayment />} />
          <Route path='/VendorReturns' element={< VendorReturns />} />
          <Route path='/VendorInvoices' element={< VendorInvoices />} />
          <Route path='/VendorPurchaseOrders' element={< VendorPurchaseOrders />} />
          <Route path='/VendorCategories' element={< VendorCategories />} />
          <Route path='/ListVendors' element={< ListVendors />} />
          <Route path='/VendorAdjustments' element={< VendorAdjustments />} />
          <Route path='/Upload' element={< Upload />} />
          <Route path='/Note' element={< Notes />} />
          <Route path='/Payment' element={< Payment />} />
          <Route path='/AdditionalContact' element={< AdditionalContact />} />
          <Route path='/Address' element={< Address />} />
          <Route path='/BankDetails' element={< BankDetails />} />  
          <Route path='/CreateNewVendor' element={<CreateNewVendor />} />
          <Route path='/VendorDashboard' element={<VendorDashboard />} />
          <Route path='/RecurringInvoice' element={<RecurringInvoice />} />
          <Route path='/NewCustomerCategory' element={<NewCustomerCategory />} />
          <Route path='/CustomerWriteOffs' element={<CustomerWriteOffs />} />
          <Route path='/ProcessReceipt' element={<ProcessReceipt />} />
          <Route path='/CustomerSalesOrder' element={<CustomerInvoices />} />
          <Route path='/CustomerWrite' element={<CustomerWrite />} />
          <Route path='/ListSales' element={<ListSales />} />
          <Route path='/CustomerReceipt' element={<CustomerReceipt />} />
          <Route path='/CustomerCredit' element={<CustomerCredit />} />
          <Route path='/CustomerCategories' element={<CustomerCategories />} />
          <Route path='/sales' element={<CustomersSales />} />
          <Route path='/list' element={<ListUsers />} />
          <Route path='/csdasboard' element={<CustomersDashBoard />} />
          <Route path='/invoice' element={<Invoice />} />
          <Route path='/add-customer' element={<AddNewCustomer />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/user-dashboard' element={<UserHomePage />} />
          <Route path='/online' element={<Online />} />
          <Route path='/support' element={<ClientsSupport />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/partners' element={<Partners />} />
          <Route path='/price' element={<Pricing />} />
          <Route path='/features' element={<Features />} />
          <Route path="/token" element={<Otp />} />
          <Route path='/reg' element={<Register />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/verify-email' element={<VerifyEmail />} />
          <Route path="/SuperAdminLogin" element={<SuperAdminLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path='/' element={<Homes />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
