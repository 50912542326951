import React from 'react'
import Sidebar from './Sidebar'
import NavBoard from '../dashboard/NavBoard'
import { NavLink } from "react-router-dom";

const PlanAnalysis = () => {
    return (
        <div id="HomeDashboard">
            <Sidebar />
            <div id="MainContent" >
                <NavBoard />
                <div id="cashNav" className='mt-2 p-2'>
                    <ul>
                        <CashNav title="Chart Analysis" />
                        <CashNav title="Profit & Loss" />
                        <CashNav title="Financial Position" />
                        <CashNav title="Cash Flow" />
                        
                    </ul>
                    <div className="help">
                        <button>Help</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const CashNav = (props) => {
    return (
        <NavLink className={({ isActive }) =>
            isActive ? "act" : undefined
        }>
          {props.title}  
        </NavLink>
    )
   
}
export default PlanAnalysis